.fondo{
    /* background-image: url("../Home/img/fondoProduct.jpg"); 
    */
    background-color: #e9ecef;
    height: 100%;
    width: 100%;
}

.MuiPaper-rounded {
  border-radius: 0px !important; 
}

@media only screen 
and (min-width: 685px)
{
  .hiddenMobile{
    display: none !important;
  }
}

@media only screen 
and (max-width: 685px)
{
  .hiddenDesktop{
    display: none !important;
  }
  
}
