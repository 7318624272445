body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
	background-color: rgba(245, 245, 245, 0.37);
}
::-webkit-scrollbar:hover {
  width: 10px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: rgba(245, 245, 245, 0.384);

   
}

::-webkit-scrollbar-thumb {

	background-image: -webkit-gradient(linear,
									   left bottom,
									   left top,
									   color-stop(0.44, rgb(151, 151, 151)),
									   color-stop(0.72, rgb(71, 71, 71)),
									   color-stop(0.86, #0f0f0f));

}

